<template>
  <div>
    <div class="text-center mt-5" v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"
        width="4"
        size="60"
      ></v-progress-circular>
    </div>
    <div v-else>
      <template v-if="!error">
        <router-view></router-view>
      </template>
      <template v-else>
        <div class="text-center"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      try {
        vm.getBulksmsUser();
      } catch (error) {
        vm.error = true;
        let message = "error_codes.error_occured";
        if (error && error.response) {
          const data = error.response.data;
          const status = error.response.status;
          if (status === 422) {
            if (data.code === "E101" || data.code === "E100") {
              message = "bulksms.error.disabled";
            } else if (data.code === "E102") {
              message = "bulksms.error.disabled";
            } else if (data.code === "P101") {
              if (data.details.code === 401) {
                message = "bulksms.error.disabled";
              }
            }
          }
        }
        vm.notify({ message: vm.$t(message) });
      }
      vm.loading = false;
    });
  },
  metaInfo() {
    return {
      title: "BulkSMS",
    };
  },
  data: () => ({
    loading: true,
    error: false,
  }),
  methods: {
    ...mapActions({
      getBulksmsUser: "bulksms/getUser",
      notify: "notification/notify",
    }),
  },
};
</script>